import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['legendTime', 'legendLabel', 'yAxes', 'xAxes', 'legend', 'hiddenLegends', 'update'],
  data(){
    return{      
        options: {
          scales: {
            yAxes: [{
              display: this.yAxes?.display,
              ticks: {
                display: this.yAxes?.ticks?.display,
                beginAtZero: this.yAxes?.ticks?.beginAtZero || true,
                reverse: this.yAxes?.ticks?.reverse || false,
                fontColor: this.yAxes?.ticks?.fontColor || '#000'
              },
              gridLines: {
                display: this.yAxes?.gridLines?.display
              }
            }],
            xAxes: [{
              display: this.xAxes?.display,
              ticks: {
                display: this.xAxes?.ticks?.display,
                beginAtZero: this.xAxes?.ticks?.beginAtZero || true,
                fontColor: this.xAxes?.ticks?.fontColor || '#000'
              },
              gridLines: {
                display: this.xAxes?.gridLines?.display
              }
            }]
          },
          legend: {
            display: this.legend?.display || true,
            labels: {
              fontColor: this.legend?.labels?.fontColor,
              filter: (item, chart) => {
                if(this.hiddenLegends)
                  return !this.hiddenLegends.includes(item.text)
                return true
              }
            }
          },
          tooltips: {
            enabled: true,
            titleColor: '#000',
            bodyColor: 'black',
            footerColor: '#000',
            // backgroundColor: '#fff',
            displayColors: false,
            cornerRadius: 2,
            mode: 'single', // index can be useful
            callbacks: {
              label: (tooltipItems,context) => {
                if(this.legendTime) {
                  const ms = tooltipItems.yLabel
                  const label = context.datasets[tooltipItems.datasetIndex].label
                  if(ms) {
                      const pad = function(n){ return n < 10 ? '0' + n : n; }
                      const days = Math.floor(ms / (24*60*60*1000))
                      const daysms = ms % (24*60*60*1000)
                      const hours = Math.floor(daysms / (60*60*1000))
                      const hoursms = ms % (60*60*1000)
                      const minutes = Math.floor(hoursms / (60*1000))
                      const minutesms = ms % (60*1000)
                      const sec = Math.floor(minutesms / 1000)
                      return label + ": " + days + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(sec)
                  }
                  return `${label}: 00:00:00:00`
                }

                if(this.legendLabel) {
                  const label = context.datasets[tooltipItems.datasetIndex].label
                  return `${label}: ${tooltipItems.yLabel}`
                }

                return `${tooltipItems.yLabel}`
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          height: 200
        }
      
    }
  },  
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    "update": {
      handler() {
        this.$data._chart.update();
      }
    }
  }
}